import React from "react"

const BuildingViewFloor5 = () => {
  return (
    <>
      <img
        src={require("assets/images/apartments/Mazowiecka_+5.png").default}
        alt=""
      />
      <svg
        version="1.1"
        id="poziom_x2B_5"
        x="0px"
        y="0px"
        viewBox="0 0 1090 602"
      >
        <polygon
          id="_x35_MA1"
          className="st0"
          points="277.1,304.5 295,256.2 309.8,213.8 288.8,206.6 307.2,157.1 408.2,194.5 389.8,244.7 
	366.7,236.5 353.3,274.6 337.7,269.1 337.8,326.8 "
          onClick={() => window.open("/mieszkania/a34-3/", "_blank")}
        />
        <polygon
          id="_x35_MA2"
          className="st0"
          points="192.2,257.6 258.9,281.9 261.1,276 282.4,284.4 307.5,215.2 286.1,207.4 304.7,156.3 
	260.9,140.4 244.5,186.6 221.2,178 "
          onClick={() => window.open("/mieszkania/a35-3/", "_blank")}
        />
        <polygon
          id="_x35_MA3"
          className="st0"
          points="254.6,283.1 245.2,310.1 247.6,311.4 244.5,321.2 305.1,343.8 305.1,376.9 215.1,376.9 
	215.1,401.1 137.8,401.1 149.4,345.8 173.2,354.4 185.4,297.2 178.9,295 192.2,259.6 "
          onClick={() => window.open("/mieszkania/a36-3/", "_blank")}
        />
        <polygon
          id="_x35_MA4"
          className="st0"
          points="260.1,379.5 260.1,403.2 305.1,403.2 305.1,479.4 143.1,482.9 125.1,463.4 129.6,441.9 
	153.3,447.2 162.5,403.2 216.1,403.2 216.5,379.5 "
          onClick={() => window.open("/mieszkania/a37-3/", "_blank")}
        />
        <polygon
          id="_x35_MA5"
          className="st0"
          points="339.7,379.5 339.7,402.6 306.9,402.6 306.9,477.4 384.1,477.4 384.1,451.6 477.5,451.6 
	477.5,477.4 520.3,477.4 520.3,403.9 474.7,403.9 474.7,395.9 447.7,395.9 447.7,379.5 "
          onClick={() => window.open("/mieszkania/a38-3/", "_blank")}
        />
        <polygon
          id="_x35_MA6"
          className="st0"
          points="421.3,303.2 437.6,303.2 437.6,298.9 469.8,298.9 469.8,303.2 521.1,303.2 521.1,401.9 
	477.1,401.9 477.1,394.4 448.7,394.4 448.7,377.6 421.3,377.6 "
          onClick={() => window.open("/mieszkania/a39-3/", "_blank")}
        />
        <polygon
          id="_x35_MB1"
          className="st0"
          points="749.6,296 749.6,377.2 931.6,377.2 931.6,321 896,321 896,296 "
          onClick={() => window.open("/mieszkania/b44-2/", "_blank")}
        />
        <polygon
          id="_x35_MB2"
          className="st0"
          points="526,306.4 610.9,306.4 610.9,331.2 677.6,331.2 677.6,306.4 747.1,306.4 747.1,377.2 
	677.4,377.2 677.4,388.9 526,388.9 "
          onClick={() => window.open("/mieszkania/b45-3/", "_blank")}
        />
        <polygon
          id="_x35_MB3"
          className="st0"
          points="526,391.4 676.5,391.4 676.5,403.4 747.1,403.4 747.1,474.6 677.4,474.6 677.4,448.4 
	621.7,448.4 621.7,474.6 526,474.6 "
          onClick={() => window.open("/mieszkania/b46-3/", "_blank")}
        />
        <polygon
          id="_x35_MB4"
          className="st0"
          points="749.6,403.4 749.6,483.6 918.3,483.6 918.3,440.9 931.6,440.9 931.6,403.4 "
          onClick={() => window.open("/mieszkania/b47-3/", "_blank")}
        />
      </svg>
    </>
  )
}

export default BuildingViewFloor5
