import "./styles.scss"

import React from "react"

import Button from "components/Button"

const Switch = ({ view, setView }) => {
  return (
    <div className="apartments-switch">
      <Button
        className={`button--${
          view === "listing" ? "yellow" : "bordered"
        } button--narrow`}
        onClick={() => setView("listing")}
      >
        Lista mieszkań
      </Button>
      <Button
        className={`button--${
          view === "building" ? "yellow" : "bordered"
        } button--narrow`}
        onClick={() => setView("building")}
      >
        Widok budynku
      </Button>
    </div>
  )
}

export default Switch
