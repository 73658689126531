import "./styles.scss"

import React from "react"

const DollyHousePopup = ({ dollyHouseImage, setDollyHousePopup }) => {
  return (
    <button
      className="dolly-house-popup"
      onClick={() => setDollyHousePopup(false)}
    >
      <span className="dolly-house-popup__close"></span>
      <img src={dollyHouseImage} alt="" />
    </button>
  )
}

export default DollyHousePopup
