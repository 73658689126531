import React from "react"

const BuildingViewFloor1 = () => {
  return (
    <>
      <img
        src={require("assets/images/apartments/Mazowiecka_+1.png").default}
        alt=""
      />
      <svg
        version="1.1"
        id="poziom_x2B_1"
        x="0px"
        y="0px"
        viewBox="0 0 1090 602"
      >
        <polygon
          id="_x31_MA2"
          className="st0"
          points="169.5,291.9 249.3,297.5 254.5,283.1 258.1,284.8 261.3,276.2 282,284.4 292.7,255.4 
	271.3,247.4 304.5,156.2 229.1,128.6 "
          onClick={() => window.open("/mieszkania/a3-3/", "_blank")}
        />
        <polygon
          id="_x31_MA1"
          className="st0"
          points="277.1,304.5 293.1,261.2 295.8,253.7 274.3,245.7 307.2,157.1 418.7,198.4 419.2,276 
	356.8,275.9 337.7,269.1 337.8,326.8 "
          onClick={() => window.open("/mieszkania/2-2/", "_blank")}
        />
        <polygon
          id="_x31_MA4"
          className="st0"
          points="384.8,403.6 384.8,379.5 340.1,379.5 340.1,403.6 307.5,403.6 307.5,501.6 349.5,501.6 
	349.5,483.9 384,483.9 384,501.6 429.5,501.6 429.5,403.6 "
          onClick={() => window.open("/mieszkania/a4-3/", "_blank")}
        />
        <polygon
          id="_x31_MA5"
          className="st0"
          points="477.3,501.6 477.3,483.9 520.9,483.9 520.9,400.6 520.9,379.5 421.7,379.5 421.7,400.6 
	432.5,400.6 432.5,501.6 "
          onClick={() => window.open("/mieszkania/a5-3/", "_blank")}
        />
        <rect
          id="_x31_MA6"
          x="421.7"
          y="278.6"
          className="st0"
          width="99.2"
          height="98"
          onClick={() => window.open("/mieszkania/a6-3/", "_blank")}
        />
        <rect
          id="_x31_MB1"
          x="842.2"
          y="278.6"
          className="st0"
          width="89.2"
          height="98"
          onClick={() => window.open("/mieszkania/b5-3/", "_blank")}
        />
        <rect
          id="_x31_MB2"
          x="749.8"
          y="278.6"
          className="st0"
          width="89.2"
          height="98"
          onClick={() => window.open("/mieszkania/b6-3/", "_blank")}
        />
        <polygon
          id="_x31_MB3"
          className="st0"
          points="724.6,278.6 687.6,278.6 665.1,278.6 665.1,335.1 687.6,335.1 687.6,376.6 747.1,376.6 
	747.1,278.6 "
          onClick={() => window.open("/mieszkania/b7-3/", "_blank")}
        />
        <polygon
          id="_x31_MB4"
          className="st0"
          points="662.4,337.1 662.4,278.6 612.7,278.6 612.7,376.6 662.4,376.6 685.5,376.6 685.5,337.1 
	"
          onClick={() => window.open("/mieszkania/b8-3/", "_blank")}
        />
        <polygon
          id="_x31_MB5"
          className="st0"
          points="611.4,278.6 525.4,278.6 525.4,376.6 548.5,376.6 611.4,376.6 "
          onClick={() => window.open("/mieszkania/b9-3/", "_blank")}
        />
        <polygon
          id="_x31_MB6"
          className="st0"
          points="582.5,403.6 582.5,379.5 525.4,379.5 525.4,403.6 525.4,477.6 525.4,483.9 564.7,483.9 
	564.7,501.6 611.4,501.6 611.4,403.6 "
          onClick={() => window.open("/mieszkania/b10-3/", "_blank")}
        />
        <polygon
          id="_x31_MB7"
          className="st0"
          points="613.8,403.6 613.8,501.6 665.3,501.6 665.3,483.9 702.1,483.9 702.1,501.6 747.1,501.6 
	747.1,403.6 "
          onClick={() => window.open("/mieszkania/b11-3/", "_blank")}
        />
        <polygon
          id="_x31_MB8"
          className="st0"
          points="750,403.6 750,483.9 791.3,483.9 791.3,501.6 839.2,501.6 839.2,403.6 "
          onClick={() => window.open("/mieszkania/b12-3/", "_blank")}
        />
        <polygon
          id="_x31_MB9"
          className="st0"
          points="931.4,440.2 931.4,403.6 842.2,403.6 842.2,501.6 882.1,501.6 889.1,501.6 889.1,483.9 
	932.2,483.9 932.2,501.6 971.3,501.6 973.3,440.2 "
          onClick={() => window.open("/mieszkania/b13-3/", "_blank")}
        />
      </svg>
    </>
  )
}

export default BuildingViewFloor1
