import "./styles.scss"

import React, { useState } from "react"

import {
  Switch,
  BuildingViewSvg,
  BuildingViewFloor0,
  BuildingViewFloor1,
  BuildingViewFloor2,
  BuildingViewFloor3,
  BuildingViewFloor4,
  BuildingViewFloor5,
} from "../components"

const BuildingView = ({ view, setView }) => {
  const [floor, setFloor] = useState(0)

  const buildingViewData = {
    view,
    setView,
    floor,
    setFloor,
  }

  const floorView = () => {
    switch (floor) {
      case 0:
        return <BuildingViewFloor0 />
      case 1:
        return <BuildingViewFloor1 />
      case 2:
        return <BuildingViewFloor2 />
      case 3:
        return <BuildingViewFloor3 />
      case 4:
        return <BuildingViewFloor4 />
      case 5:
        return <BuildingViewFloor5 />

      default:
        return <h2>Brak wybranego piętra</h2>
    }
  }

  return (
    <section className="apartments-building-view">
      <div className="container-fluid">
        <div className="row flex-column-reverse flex-xl-row">
          <div className="col-xl-6 offset-xl-3 text-center">
            <h2>Wybierz mieszkanie</h2>
          </div>
          <div className="col-xl-3">
            <Switch {...buildingViewData} />
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="apartments-building-view__building">
              <BuildingViewSvg {...buildingViewData} />
            </div>
          </div>
        </div>

        <div className="apartments-building-view__floors" id="floors">
          <div className="row align-items-center">
            <div className="col-md-1">
              <div className="apartments-building-view__floors-nav">
                {Array(6)
                  .fill(null)
                  .map((item, index) => (
                    <button
                      key={index}
                      className={floor === index ? "current" : ""}
                      onClick={() => setFloor(index)}
                    >
                      {index}
                    </button>
                  ))}
                <strong>Piętro</strong>
              </div>
            </div>
            <div className="col-md-11">
              <div className="apartments-building-view__floors-view">
                {floorView()}
                <img
                  className="apartments-building-view__floors-compass"
                  src={require("assets/icons/ico-compass.svg").default}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BuildingView
