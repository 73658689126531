import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import { Listing, BuildingView } from "page_components/apartments"

const Apartments = ({ data }) => {
  const [view, setView] = useState("building")
  const allApartments = data.allWpApartment.nodes

  const apartmentsData = {
    view,
    setView,
  }

  return (
    <Layout seo={{ title: "Mieszkania" }}>
      <PageHeader title="Mieszkania" />
      {view === "listing" && (
        <Listing apartmentList={allApartments} {...apartmentsData} />
      )}
      {view === "building" && (
        <BuildingView apartmentList={allApartments} {...apartmentsData} />
      )}
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpApartment(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        slug
        acfApartment {
          apartmentNumber
          buildingNumber
          area
          floor
          location
          price
          regularPrice
          discountText
          priceForSquareMeter
          roomsnumber
          status
          dollhouseView
        }
      }
    }
  }
`

export default Apartments
