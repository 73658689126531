import React from "react"

const BuildingViewFloor0 = () => {
  return (
    <>
      <img
        src={require("assets/images/apartments/Mazowiecka_+0.png").default}
        alt=""
      />
      <svg
        version="1.1"
        id="poziom_x5F_0"
        x="0px"
        y="0px"
        viewBox="0 0 1090 602"
      >
        <rect
          id="_x30_MA1"
          x="421.7"
          y="278.6"
          className="st0"
          width="99.2"
          height="98"
          onClick={() => window.open("/mieszkania/1-2/", "_blank")}
        />
        <rect
          id="_x30_MB2"
          x="749.8"
          y="278.6"
          className="st0"
          width="89.2"
          height="98"
          onClick={() => window.open("/mieszkania/b2-3/", "_blank")}
        />
        <rect
          id="_x30_MB1"
          x="842.2"
          y="278.6"
          className="st0"
          width="89.2"
          height="98"
          onClick={() => window.open("/mieszkania/b1-3/", "_blank")}
        />
        <polygon
          id="_x30_MB4"
          className="st0"
          points="612.1,278.6 525.4,278.6 525.4,376.6 548.5,376.6 612.1,376.6 "
          onClick={() => window.open("/mieszkania/b4-3/", "_blank")}
        />
        <polygon
          id="_x30_MB3"
          className="st0"
          points="748.1,278.6 637.6,278.6 637.6,376.6 667,376.6 748.1,376.6 "
          onClick={() => window.open("/mieszkania/b3-3/", "_blank")}
        />
      </svg>
    </>
  )
}

export default BuildingViewFloor0
