import React from "react"
import scrollToElement from "scroll-to-element"

const BuildingViewSvg = ({ setFloor }) => {
  return (
    <>
      <img
        src={require("assets/images/apartments/Mazowiecka_Budynek.jpg").default}
        alt=""
      />
      <svg version="1.1" id="poziomy" x="0px" y="0px" viewBox="0 0 1433 620">
        <polygon
          onClick={() => {
            setFloor(0)
            scrollToElement("#floors")
          }}
          id="_x30_"
          className="st0"
          points="854,482.8 608.3,473.8 608.3,451.1 294.8,438.6 225.2,438.6 134.9,456.3 134.9,559.8 
453.2,551.9 453.2,531.6 1235.9,524.8 1235.9,490.4 854,473.8 "
        />
        <polygon
          onClick={() => {
            setFloor(1)
            scrollToElement("#floors")
          }}
          id="_x31_"
          className="st0"
          points="854,422.9 609.8,404.5 303.8,382.6 303.8,362.7 224,362.7 224,383 141.8,407.9 141.8,408.4 
134.9,411.4 134.9,456.3 225.2,438.6 294.8,438.6 608.3,451.1 608.3,473.8 854,482.8 854,473.8 1235.9,490.4 1235.9,444.9 
854,417.1 "
        />
        <polygon
          onClick={() => {
            setFloor(2)
            scrollToElement("#floors")
          }}
          id="_x32_"
          className="st0"
          points="854.8,371.3 592.6,345.1 326.5,320.4 303.8,318.4 227.9,318.4 141.8,355.4 141.8,407.9 
224,383 224,362.7 303.8,362.7 303.8,382.6 609.8,404.5 854,422.9 854,417.1 1235.9,444.9 1235.9,396.7 854.8,356.4 "
        />
        <polygon
          onClick={() => {
            setFloor(3)
            scrollToElement("#floors")
          }}
          id="_x33_"
          className="st0"
          points="854,293.4 854,310 593.4,275 292.5,234.3 230.6,234.3 141.8,286.1 141.8,355.4 227.9,318.4 
303.8,318.4 326.5,320.4 592.6,345.1 854.8,371.3 854.8,356.4 1235.9,396.7 1235.9,348.7 "
        />
        <polygon
          onClick={() => {
            setFloor(4)
            scrollToElement("#floors")
          }}
          id="_x34_"
          className="st0"
          points="292.5,234.3 593.4,275 854,310 854,293.4 1235.9,348.7 1235.9,284.8 1233.1,282.9 
1064.5,251.1 1064.5,261.3 852,223.8 852,232.8 591.8,186.2 591.8,198.3 292.1,144.7 234.8,146.9 227.3,146.9 137.5,214.9 
137.5,216.7 134.9,218.7 134.9,222 137.5,221.8 137.3,223.9 141.8,224.7 141.8,286.1 230.6,234.3 "
        />
        <polygon
          onClick={() => {
            setFloor(5)
            scrollToElement("#floors")
          }}
          id="_x35__00000022537852727305210680000017390054501967803025_"
          className="st0"
          points="591.8,198.3 591.8,186.2 852,232.8 
852,223.8 1064.5,261.3 1064.5,251.1 1233.1,282.9 1223.8,276.4 1223.8,269.7 1209.2,266 1180.2,247.3 1005.9,211 1004.9,218.8 
784.3,170.5 784.3,183.8 533.1,135.3 533.1,146.7 312,109.9 299.7,115.9 234.8,146.9 292.1,144.7 "
        />
        <polygon
          onClick={() => {
            setFloor(5)
            scrollToElement("#floors")
          }}
          id="_x36_"
          className="st0"
          points="533.1,146.7 533.1,135.3 784.3,183.8 784.3,170.5 1004.9,218.8 1005.9,211 1180.2,247.3 
1117.3,206.5 1114.2,206.5 1113.2,207.8 1109.5,206.5 1109,195.1 1110.8,195.1 1110.8,187.5 1101.9,185.1 1086.3,188.5 
1086.3,196.1 1087.9,195.8 1087.9,202.1 1065.9,196.9 1066.2,190.6 1056.3,188.3 1044.6,190.4 1045.3,192.2 1038,190.6 
1038.3,184.1 1040.4,184.1 1040.4,175.5 1021.5,171.3 1001.1,175.8 1000.9,182.3 979.4,178 979.2,164.7 980.8,164.7 981,156.4 
963.6,151.9 927.6,160 916.2,157.2 898.2,161.1 898,169.2 841.7,156.6 842.1,148.4 844,147.3 843.6,138.8 813.1,132.6 800,136.1 
799.8,138.6 796.9,140 796.7,146.7 771,141.2 770.6,126.9 772.4,125.9 772.2,116.9 750.5,112.2 703.5,125.1 703.1,134.1 
705.1,134.1 704.9,137.3 653.4,126.3 653.3,115.2 655,115 654.8,105.2 638.8,101.7 625.3,105.4 625.7,111.6 613.5,109.3 
600.8,113.4 601,115 585.9,112 586.5,107.7 587.9,106.9 588.3,97.4 572.8,94.4 558.8,98.9 559.5,106.2 521.4,97.9 521.2,84.3 
522.8,84.3 522.9,70 492.4,63.9 425.3,88.8 390.1,81.9 390.7,71.1 350.6,65.7 341.2,72.3 341.2,82.3 343.3,81.7 342.9,95.8 
320.4,106.8 302.4,104.6 299.1,108.7 299.7,115.9 312,109.9 "
        />
      </svg>
    </>
  )
}

export default BuildingViewSvg
